import "../i18n";
import Cookies from 'js-cookie';
import { updateCartIcons } from './updateCartIcons';

document.addEventListener("DOMContentLoaded", () => {
  const addToCartLinks = document.querySelectorAll("a[data-add-to-cart]");
  const shoppingBagIcons = document.querySelectorAll("[data-shopping-cart");
  
  const csrftoken = Cookies.get('csrftoken');
  let itemCount = 0;
  
  if (addToCartLinks.length) {
    // add script tag
	  const script = document.createElement("script");
	  script.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
	  script.type = "module";
	  document.body.appendChild(script);
  }
  
  addToCartLinks.forEach(link => {
    const containerLi = link.closest("li");
    
	  // define this before if-statement to add to scope
    link.addEventListener("click", async (event) => {
      event.preventDefault();
	    const animationHTML = document.createElement("div");

	    // preload animation
	    animationHTML.innerHTML = `
        <dotlottie-player src="https://lottie.host/47f11aa7-d0e5-4b70-b838-204d55562e88/440uzRAO7S.json" background="transparent" speed="1" style="width: 200px; height: 200px;" autoplay></dotlottie-player>
        <a class="btn btn-white btn--view-cart" href="${window.gettext('/store/cart/')}">${window.gettext('View Cart')} <svg class="icon" width="14" height="14"><use href="#shopping-bag"/></svg></a>
      `;
	    animationHTML.classList.add("lottie");
      const addToCartValue = event.target.getAttribute('data-add-to-cart');

      try {
        const response = await fetch("/store/_api/cart/", {
          method: "POST",
          headers: {"Content-Type": "application/json", "X-CSRFToken": csrftoken},
          body: JSON.stringify({product: addToCartValue, quantity: 1}),
        });
        const responseObj = await response.json();

        // animate the add-to-cart item and container <li>
        event.target.classList.add("adding-to-cart");
        containerLi?.appendChild(animationHTML);


        // animate both shopping bag icons
        shoppingBagIcons.forEach(item => {
          item.classList.add("adding-to-cart");
          // const shoppingBagDot = item.querySelector(".cart-items-amount");

          // increment up the items in shopping bag
          if (responseObj.count == 1) {
            // item.querySelector(".cart_amount").innerHTML = `<span class="cart-items-amount">1</span>`;
            updateCartIcons(1);
          } else {
            updateCartIcons(responseObj.count);
            // shoppingBagDot.innerHTML = responseObj.count;
          } 
        }); 




        setTimeout(() => {
          animationHTML.remove();
          event.target.classList.remove("adding-to-cart");
          shoppingBagIcons.forEach(item => {
            item.classList.remove("adding-to-cart");
          });
        }, 5000);

      } catch (error) {
        console.log(error);
      }
    });
  });


});




